/* eslint-disable */
export const messages = {
  customer_welcome_news: "Neuigkeiten",
  customer_welcome_news_title_08_04_2024: "Neues Modul der GLOBAL SUITE",
  customer_welcome_news_content_08_04_2024: `GLOBAL CLIMATE freut sich, das neue Modul zum CSRD-Management vorstellen zu können: <ul class="ma-1"> <li>Schrittweise Durchführung der doppelten Wesentlichkeitsanalyse gemäß der CSRD und den ESRS</li> <li>Einbindung von internen und externen Stakeholdern</li> <li>Automatische Auswahl der berichtspflichtigen Angaben und Datenpunkte anhand der Wesentlichkeitsanalyse</li> <li>Erstellung des Nachhaltigkeitsberichts</li> <li>Prüfungsfähige und transparente Dokumentation des gesamten Prozesses</li><li>Zuordnung von Verantwortlichkeiten und Freigabeprozesse</li></ul>Stehen auch Sie vor der Herausforderung Ihren CSRD-Pflichten nachzukommen, dann zögern Sie nicht auf uns zuzukommen.`,
  customer_welcome_news_title_22_09_2023: "Neue Funktionen der GLOBAL SUITE",
  customer_welcome_news_content_22_09_2023: ` GLOBAL CLIMATE freut sich, die folgenden neuen Funktionen der GLOBAL SUITE vorstellen zu können: <ul class="ma-1"> <li>Einführung eines FAQ-Bereichs</li> <li>Neue Landing Page mit direkter Anzeige aller offenen Umfragen</li> <li>Neugestaltung des Lieferanten-ESG-Ratings für bessere Benutzerfreundlichkeit</li> <li>Neuer, verbesserter Partnerregistrierungsprozess</li> <li>Mehrere kleinere Verbesserungen und Fehlerbehebungen in GLOBAL FOOTPRINT</li> </ul> `,
  customer_welcome_news_title_02_08_2023: "Verbesserung der GLOBAL SUITE",
  customer_welcome_news_title_11_06_2023: "Verbesserung der GLOBAL SUITE",
  customer_welcome_news_content_11_06_2023: `GLOBAL CLIMATE freut sich Ihnen die folgenden Verbesserungen zur Verfügung zu stellen: <ul class="ma-1"> <li>Verbesserte Navigation durch explizite Trennung der einzelnen Produkte</li> <li>Kombinieren mehrerer Pendler:innen Umfragen zu einem Link</li> </ul>`,
  customer_welcome_news_title_18_05_2023: "Verbesserung der GLOBAL SUITE",
  customer_welcome_news_content_18_05_2023: `GLOBAL CLIMATE freut sich Ihnen verbesserte Features zur Verfügung zu stellen: <ul class="ma-1"> <li>Verbesserung des Unit Buchungen Excel Uploads</li> <li>Konfiguration von Start und Ziel von Logistik-Ketten</li> </ul>`,
  customer_welcome_news_title_21_01_2023: "Verbesserung der GLOBAL SUITE",
  customer_welcome_news_content_21_01_2023: `GLOBAL CLIMATE freut sich Ihnen weitere neue Features zur Verfügung zu stellen: <ul class="ma-1"> <li>Neues individuell anpassbares GLOBAL FOOTPRINT Dashboard</li> <li>Verbesserung der Excel Buchungs-Exporte</li> <li>Ermöglichung der Anmeldung in mehrere Konten mit den gleichen Zugangsdaten</li> </ul> Wenn Sie Fragen haben oder Hilfe bei der Konfiguration Ihres neuen GLOBAL FOOTPRINT Dashboards benötigen, zögern Sie bitte nicht, uns zu kontaktieren.`,
  customer_welcome_news_title_01_10_2022: "Verbesserung der GLOBAL SUITE",
  customer_welcome_news_content_01_10_2022: `GLOBAL CLIMATE freut sich Ihnen weitere neue Features der GLOBAL SUITE zu präsentieren. Es wurden folgende Verbesserungen im Bereich GLOBAL FOOTPRINT implementiert: <ul class="ma-1"> <li>Besseres Filtern von Unit Buchungen und exportieren der Daten</li> <li>Versionierung der kundenspezifischen CO<sub>2</sub> Datenbank</li> </ul> Wir freuen uns über Anregungen und Kritik, zögern Sie nicht auf uns zuzukommen.`,
  customer_welcome_news_title_03_09_2022: "Verbesserung der GLOBAL SUITE",
  customer_welcome_news_content_03_09_2022: `GLOBAL CLIMATE freut sich Ihnen die nächste Version der GLOBAL SUITE zu präsentieren. <br> Die neue Version beinhaltet folgenden Verbesserungen: <ul class="ma-1"> <li> Überarbeitete, schnellere Navigation <ul> <li>Alle Produkte sind nun mit einem Klick erreichbar</li> <li>Viele Detailseiten wurden durch Dialoge ersetzt</li> <li>Vereinheitlichung des Stylings</li> </ul> </li> <li>Schnellere Ladezeiten der Anwendung</li> </ul> Wir freuen uns über Anregungen und Kritik, zögern Sie nicht auf uns zuzukommen.`,
  customer_welcome_news_title_17_06_2022: "Erweiterung der GLOBAL CLIMATE Automation API",
  customer_welcome_news_content_17_06_2022: `GLOBAL CLIMATE freut sich Ihnen mitzuteilen, dass die Funktionalitäten der Automation API erweitert wurden. <br> Es wurden folgende Verbesserungen im Bereich der GLOBAL FOOTPRINT Unit Buchungen implementiert: <ul class="ma-1"> <li>Rückgabe der CO<sub>2</sub> Emissionen aufgeteilt auf Scopes beim Erstellen von Unit Buchungen</li> <li>Batch Support beim Abfragen Unit Buchungen</li> <li>Batch Support beim Stornieren von Unit Buchungen</li> </ul> Bei Fragen zur Automation API zögern Sie nicht auf uns zuzukommen.`,
  customer_welcome_products: "GLOBAL CLIMATE Produkte",
  customer_welcome_esg_benefit_1:
    "Professionelle ESG-Bewertung Ihres Unternehmens durch Analysten von GLOBAL CLIMATE",
  customer_welcome_esg_benefit_2: "Überwachung der Lieferanten Compliance",
  customer_welcome_esg_benefit_3: "Automatisierte globale THG-Ermittlung Ihrer Lieferanten",
  customer_welcome_esg_benefit_4:
    "ESG-Scorecard und Nachhaltigkeitszertifikat zum Teilen und kommunizieren mit Ihren Geschäftspartnern",
  customer_welcome_esg_benefit_5: "Unbegrenzte Lieferantenbewertungen weltweit in verschiedenen Sprachen",
  customer_welcome_esg_benefit_6: "Umfangreiche KPI`s nach GRI zur Nachhaltigkeitsberichterstattung",
  customer_welcome_esg_benefit_7: "Evaluierung der eigenen CSR",
  customer_welcome_benefit_when_using_sco2pes: "GLOBAL FOOTPRINT CO<sub>2</sub> Accounting",
  customer_welcome_sco2pes_benefit_1:
    "Prozessintegrierte und finanzdatenbasierte CO<sub>2</sub> Bilanzierung nach dem GHG-Protokoll",
  customer_welcome_sco2pes_benefit_2:
    "Verwendung von Echtwerten Ihres Unternehmens anstelle von Schätzungen und Statistiken",
  customer_welcome_sco2pes_benefit_3: "Ergebnisse in Echtzeit",
  customer_welcome_sco2pes_benefit_4: "Vollständige CO<sub>2</sub>-Bilanz (Scope 1, Scope 2 und Scope 3)",
  customer_welcome_sco2pes_benefit_5: "Wissenschaftlich geprüfte CO<sub>2</sub>-Datenbank",
  customer_welcome_sco2pes_benefit_6: "Austomatisierte Verbuchung von CO<sub>2</sub>-relevanten Geschäftsvorfällen",
  customer_welcome_sco2pes_benefit_7: "Schnittstelle zu SAP, Microsoft Dynamics uns DATEV",
  customer_welcome_dialog_title: "Willkommen auf der GLOBAL SUITE",
  customer_welcome_dialog_description:
    "Sie haben sich erfolgreich auf der GLOBAL SUITE registriert.<br />Schauen Sie sich gerne auf der Plattform um. Ihre offenen Umfragen finden Sie im Navigationsmenü unter Umfragen.",
  customer_freemium_dialog_title: "Willkommen beim<br />CSRD-Software-Tasting",
  customer_freemium_dialog_description:
    'Schön, dass Sie unser CSRD-Tool testen und die Gelegenheit nutzen, unsere Kernfunktionalitäten („Themenauswahl“, „Stakeholder Feedback“ und „IRO Defintion“) der Doppelten Wesentlichkeitsanalyse kennenzulernen. So bekommen Sie einen kleinen Vorgeschmack darauf, was in unser GLOBAL SUITE möglich ist.<br /><strong>Diese Freemium-Lizenz ist gültig bis {0}</strong> (Testzeitraum 6 Wochen).<br /><br /><strong>So geht’s:</strong> Klicken Sie in der linken Navigationsbar auf <strong>GLOBAL COMPLIANCE</strong> und wählen Sie dort das CSRD-Tool aus. Jetzt können Sie die drei Kernfunktionalitäten testen. Viel Spaß beim Ausprobieren der Software!<br /><br />Wenn Sie Fragen, Anregungen oder Wünsche haben, dann kontaktieren Sie uns gerne. Unser Sales-Team steht Ihnen jederzeit beratend zur Seite.',
  customer_freemium_dialog_button_demo: "Demo buchen",
  customer_welcome_news_update: "Neues Update",
  customer_welcome_banner_title_1: "Willkommen in der",
  customer_welcome_banner_title_2: "GLOBAL SUITE",
  customer_welcome_banner_title_3:
    "Die All-In-One Softwarelösung für eine <b>effiziente, gesetzeskonforme und prüfungssichere</b> Nachhaltigkeitsberichterstattung",
  customer_welcome_survey_title_1: "Sie haben eine Umfrage auszufüllen",
  customer_welcome_survey_subtitle_plural: "Sie haben <strong>{0}</strong> offene Umfragen",
  customer_welcome_survey_subtitle_singular: "Sie haben <strong>{0}</strong> offene Umfrage",
  customer_welcome_update_title: "Ein neues Update wurde veröffentlicht",
  customer_welcome_update_subtitle: "Verbesserungen der GLOBAL SUITE",
  customer_welcome_product_title: "Unsere Lösung",
  customer_welcome_product_subtitle:
    "Unsere Software bildet die gesetzlichen ESG-Vorgaben von CSRD und des LkSG vollständig ab und bringt maximale Effizienz in die Erstellung von CO<sub>2</sub>-Bilanzen und -Berichten.",
  customer_welcome_product_scopes_description:
    "Vollautomatisierte Erstellung von CO<sub>2</sub>-Bilanzen in Scope 1, 2, 3 und Berechnung von Product Carbon Footprints entlang des gesamten Produktlebenszyklus.",
  customer_welcome_product_scores_description:
    "Gesetzliche Berichtspflichten vollständig, rechtskonform und prüfungssicher erfüllen.",
  customer_welcome_ccf_product_link: "CCF-Tool",
  customer_welcome_pcf_product_link: "PCF-Tool",
  customer_welcome_sbt_product_link: "SBT-Tool",
  customer_welcome_esg_product_link: "CSRD-Tool",
  customer_welcome_lksg_product_link: "LkSG-Tool",
  customer_welcome_csrd_product_link: "ESG-Tool",
  customer_welcome_sales_title: "Kontaktieren Sie das Vertriebsteam für weitere Informationen",
  customer_welcome_sales_subtitle:
    "Wir freuen uns darauf, von Ihnen zu hören. Kontaktieren Sie uns einfach per Telefon, E-Mail oder Kontaktformular oder buchen Sie direkt eine Demo. Wir sind bereit! ",
  customer_welcome_sales_cta_1: "Kontakt Vertrieb",
  customer_welcome_sales_cta_2: "Jetzt eine Demo buchen",
  customer_welcome_sales_dialog_title: "Wir freuen uns, von Ihnen zu hören",
  customer_welcome_sales_dialog_description:
    "Rufen Sie uns einfach an oder kontaktieren Sie uns per E-Mail. Wir sind da und freuen uns auf ein erstes Treffen, um Sie kennenzulernen!",
  customer_welcome_sales_support_ticket: "Support Ticket",
};
